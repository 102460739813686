<template>
  <div
    :class="{
      'navbar-secondary': true,
      'navbar-secondary-fixed': windowTop > 98
    }"
  >
    <b-navbar toggleable="lg" type="light" variant="light">
      <b-container>
        <b-navbar-brand v-show="windowTop > 98" href="/">
          <img
            data-v-4e9f0a80
            alt="Logo Provincie West-vlaanderen"
            src="/img/small-logo.png"
            height="20px"
          />
          West-vlaanderen
        </b-navbar-brand>
        <b-navbar-toggle
          v-show="windowTop > 98"
          target="nav-collapse"
        ></b-navbar-toggle>
        <b-nav-form @submit.prevent="search">
          <b-input-group class>
            <b-form-input
              v-model="searchTerm"
              class="my-2"
              :state="searchValidation"
              placeholder="Zoeken naar..."
            ></b-form-input>
            <b-input-group-append>
              <b-button variant="primary" class="my-2 mr-3" type="submit"
                >Zoeken</b-button
              >
            </b-input-group-append>
          </b-input-group>
        </b-nav-form>
        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav v-show="windowTop > 98" class="ml-auto">
            <b-nav-item v-if="!user.UserName">
              <router-link to="/login">Log in</router-link>
            </b-nav-item>

            <b-nav-item v-if="user.UserName">
              <router-link
                v-show="windowTop > 98"
                to="/cart"
                class="d-none d-lg-block d-xl-block"
              >
                <b-button variant="outline-primary" class="pl-3 pr-3">
                  <font-awesome-icon icon="shopping-cart" />
                  {{ cartCount > 0 ? cartCount : '' }}
                </b-button>
              </router-link>

              <router-link
                v-show="windowTop > 98"
                to="/account"
                class="d-block d-lg-none d-xl-none"
              >
                <b-button variant="link" class="pl-3 pr-3">
                  <font-awesome-icon icon="shopping-cart" />
                  {{ cartCount > 0 ? cartCount : '' }} Winkelmandje
                </b-button>
              </router-link>
            </b-nav-item>

            <b-nav-item v-if="user.UserName">
              <router-link
                v-show="windowTop > 98"
                to="/account"
                class="d-none d-lg-block d-xl-block"
              >
                <b-button variant="primary" class="pl-3 pr-3">
                  <font-awesome-icon icon="user-circle" />
                </b-button>
              </router-link>
              <router-link
                v-show="windowTop > 98"
                to="/account"
                class="d-block d-lg-none d-xl-none"
              >
                <b-button variant="link" class="pl-3 pr-3">
                  <font-awesome-icon icon="user-circle" />&nbsp;Mijn account
                </b-button>
              </router-link>
            </b-nav-item>

            <b-nav-item v-if="user.UserName">
              <button
                class="mr-3 pl-3 pr-3 d-none d-lg-block d-xl-block btn btn-secondary"
                @click="logout"
              >
                <font-awesome-icon icon="sign-out-alt" />&nbsp;Uitloggen
              </button>
              <button
                class="mr-3 pl-3 pr-3 d-block d-lg-none btn btn-link"
                @click="logout"
              >
                <font-awesome-icon icon="sign-out-alt" />&nbsp;Uitloggen
              </button>
            </b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </b-container>
    </b-navbar>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      searchTerm: '',
      windowTop: 0
    }
  },
  computed: {
    searchValidation: function() {
      if (
        this.searchTerm.length > 1 ||
        this.searchTerm.length == 0 ||
        this.searchTerm == ''
      ) {
        return null
      } else {
        return false
      }
    },
    user: function() {
      return this.$store.getters.user
    },
    cartCount() {
      return this.$store.getters.cartCount
    }
  },

  mounted: function() {
    window.addEventListener('scroll', this.onScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll)
  },
  methods: {
    onScroll() {
      this.windowTop =
        window.top.scrollY /* or: e.target.documentElement.scrollTop */
    },
    logout: async function() {
      await this.$store.commit('unsetUser')
      await this.$store.commit('unsetCart')

      this.$router.push({ name: 'index' })
    },

    search: async function(event) {
      event.preventDefault()
      if (this.searchTerm.length > 1) {
        await this.$router.push({
          name: 'search',
          params: { searchTerm: this.searchTerm }
        })
        this.searchTerm = ''
      }
    }
  }
}
</script>

<style scoped lang="scss">
.navbar-secondary-fixed {
  position: fixed;
  width: 100%;
  z-index: 9;
  top: 0;
  .navbar-brand {
    a {
      color: black !important;
    }
    img {
      height: 20px;
    }
  }
}
.form-control input {
  height: add(1.5em, add(1.1rem, 2px, false)) !important;
}
</style>
