import { getDefaultCategory, getCategories } from '@/services/CategoryService'
// initial state
const state = () => ({
  categories: [],
  defaultCategory: {}
})

// getters
const getters = {
  getCategories: state => state.categories,
  getDefaultCategory: state => state.defaultCategory,
  // eslint-disable-next-line no-unused-vars
  getCategory: state => categoryID => {
    //parse categoryID as number
    const categoryIDNumber = parseInt(categoryID)
    const categories = state.categories

    if (!categories) return []

    return (
      categories.find(category => category.CategoryID === categoryIDNumber) ??
      categories.find(category => category.Slug === categoryID) ??
      null
    )
  }
}

// actions
const actions = {
  async fetchDefaultCategory({ commit }) {
    const category = await getDefaultCategory()
    commit('setDefaultCategory', category)
  },
  async fetchCategories({ commit }) {
    const categories = await getCategories()
    commit('setCategories', categories.Collection)
  }
}

// mutations
const mutations = {
  setDefaultCategory(state, defaultCategory) {
    state.defaultCategory = defaultCategory
  },

  setCategories(state, categories) {
    state.categories = categories ?? []
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
