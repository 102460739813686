import dayjs from 'dayjs'

const { axiosCreate, api } = require('./Api')
const moment = require('moment')
const { checkResponse } = require('../controllers/errorHandler')

import store from '../state/store'

async function getCart({ warehouse = null } = {}) {
  const result = await api.get(`/v2/shop/cart`, {
    headers: { authorization: store.getters.token },
    params: {
      ContactID: store.getters.contactID,
      sorton: 'ranking',
      warehouse: warehouse
    }
  })

  if ((await checkResponse(result)) !== null) return

  if (result.data) {
    store.commit('setCart', {
      cart: result.data
    })
  }

  if (result.data.CartItems.length > 0) {
    store.commit(
      'rentalInformation/setStartDate',
      dayjs(result.data.DateTimeExpectedStart).format('YYYY-MM-DD')
    )

    store.commit(
      'rentalInformation/setEndDate',
      dayjs(result.data.DateTimeExpectedEnd).format('YYYY-MM-DD')
    )
  }

  let cartItems = []

  if (result.data.CartItems && result.data.CartItems.Collection) {
    result.data.CartItems.Collection.forEach(cartItem => {
      cartItems.push({
        item: cartItem.Description,
        cartItemID: cartItem.CartItemID,
        price: cartItem.Price,
        totalIncVAT: cartItem.TotalIncVAT,
        priceIncVAT: cartItem.PriceIncVAT,
        amount: cartItem.Amount || 0,
        delete: cartItem.CartItemID,
        total: cartItem.Price,
        maxAdded: cartItem.MaxPerCustomerLeftover,
        maxPerCustomer: cartItem.MaxPerCustomer,
        maxPerCustomerLeftover: cartItem.MaxPerCustomerLeftover
      })
    })

    cartItems = cartItems.filter(function(obj) {
      return obj.item !== 'Administratiekosten'
    })
  }

  return cartItems
}

async function checkout({ notes, CUST_ActivityDescription }) {
  const result = await axiosCreate.post(
    `cart/${store.getters.contactID}/checkout`,
    { notes, CUST_ActivityDescription },
    {
      headers: { authorization: store.getters.token }
    }
  )

  if ((await checkResponse(result)) !== null) return

  return result
}

async function addToCartSimple({ itemID, compositionID } = {}) {
  await checkCartAge()
  const result = axiosCreate.post(
    `custcart/${store.getters.contactID}/cartItems`,

    {
      ItemID: itemID,
      CompositionID: compositionID
    },

    { headers: { authorization: store.getters.token } }
  )

  if ((await checkResponse(result)) !== null) return

  return result
}

async function addToCart({
  warehouse,
  startDate,
  endDate,
  itemID,
  compositionID,
  amount
} = {}) {
  await checkCartAge()
  const result = api.post(
    `/v3/shop/cart/cartitems`,
    [
      {
        WarehouseID: warehouse,
        DateTimeBusinessStart: startDate,
        DateTimeBusinessEnd: endDate,
        DateTimeExpectedStart: startDate,
        DateTimeExpectedEnd: endDate,
        ItemID: itemID,
        CompositionID: compositionID,
        Amount: amount
      }
    ],
    {
      params: {
        contactID: store.getters.contactID
      },
      headers: { authorization: store.getters.token }
    }
  )

  if ((await checkResponse(result)) !== null) return

  return result
}

// Check if cart cache is older then a day. Carts expire at 12 AM
async function checkCartAge() {
  const cartCache = store.getters.cartCache
  const cartDate = moment(cartCache.DateTimeCreated, 'YYYY-MM-DD')
  if (moment(cartDate).isBefore(moment().format('YYYY-MM-DD'), 'day')) {
    await getCart()
  }
}

async function updateCartItem({ cartItemID, amount }) {
  try {
    const result = await axiosCreate.patch(
      `cart/${store.getters.contactID}/cartitems/${cartItemID}`,
      {
        CartItemID: cartItemID,
        Amount: amount
      },
      {
        headers: { authorization: store.getters.token }
      }
    )

    if ((await checkResponse(result)) !== null) return

    return result
  } catch (error) {
    if ((await checkResponse(error)) !== null) return
    return false
  }
}

async function updateCart({
  warehouseID,
  businessHourStart,
  businessHourEnd,
  startDate,
  endDate
} = {}) {
  const result = await axiosCreate.patch(
    `cart/${store.getters.contactID}`,
    {
      WarehouseID: warehouseID,
      BusinessHourStart: businessHourStart,
      BusinessHourEnd: businessHourEnd,
      DateTimeExpectedStart: startDate,
      DateTimeExpectedEnd: endDate,
      DateTimeBusinessStart: startDate,
      DateTimeBusinessEnd: endDate
    },
    {
      headers: { authorization: store.getters.token }
    }
  )

  if ((await checkResponse(result)) !== null) return


  return result
}

async function deleteCartItem({ cartItemID }) {
  const result = axiosCreate.delete(
    `cart/${store.getters.contactID}/cartitems/${cartItemID}`,
    {
      headers: { authorization: store.getters.token }
    }
  )

  if ((await checkResponse(result)) !== null) return

  return result
}

async function cartMutationCheck({ warehouseID, startDate, endDate } = {}) {
  const result = await axiosCreate.get(
    `cart/${store.getters.contactID}/invalidCartItems`,
    {
      params: {
        warehouseID,
        dateTimeBusinessStart: startDate,
        dateTimeBusinessEnd: endDate,
        dateTimeExpectedStart: startDate,
        dateTimeExpectedEnd: endDate
      },
      headers: { authorization: store.getters.token }
    }
  )
  if ((await checkResponse(result)) !== null) return
  return result.data
}

module.exports = {
  getCart,
  updateCartItem,
  addToCart,
  deleteCartItem,
  checkout,
  updateCart,
  cartMutationCheck,
  addToCartSimple
}
