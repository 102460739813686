import { render, staticRenderFns } from "./SecondaryNavbar.vue?vue&type=template&id=72325528&scoped=true&"
import script from "./SecondaryNavbar.vue?vue&type=script&lang=js&"
export * from "./SecondaryNavbar.vue?vue&type=script&lang=js&"
import style0 from "./SecondaryNavbar.vue?vue&type=style&index=0&id=72325528&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72325528",
  null
  
)


    import installComponents from "!/builds/rentmagic/rentmagic.website.vue.west-vlaanderen/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BNavbarBrand, BNavbarToggle, BFormInput, BButton, BInputGroupAppend, BInputGroup, BNavForm, BNavItem, BNavbarNav, BCollapse, BContainer, BNavbar} from 'bootstrap-vue'
    installComponents(component, {BNavbarBrand, BNavbarToggle, BFormInput, BButton, BInputGroupAppend, BInputGroup, BNavForm, BNavItem, BNavbarNav, BCollapse, BContainer, BNavbar})
    

export default component.exports