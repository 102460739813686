const { api } = require('./Api')
import store from '../state/store'

async function getCategories() {
  const result = await api.get('/v2/shop/categories')

  return result.data
}

async function getCategory({ categoryID = null } = {}) {
  let categoryIDList = []

  if (categoryID && categoryID.toString().includes(',')) {
    categoryIDList = categoryID.split(',')
  } else {
    categoryIDList.push(categoryID)
  }

  const result = await api.get(
    `/v2/shop/categories/${categoryIDList.reverse()[0]}`,
    {
      params: { ContactID: store.getters.contactID }
    }
  )
  return result.data
}

module.exports = { getCategories, getCategory }
