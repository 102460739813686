// initial state
import { getCustomer, patchCustomer } from '@/services/CustomerService.js'

const state = () => ({
  customer: {}
})

// getters
const getters = {
  getCustomer: state => state.customer
}

// actions
const actions = {
  async patchCustomer({ rootState, commit, state }) {
    const customer = await patchCustomer({
      customerID: rootState.account.CustomerID,
      customer: state.customer
    })
    commit('setCustomer', customer)
  },
  async fetchCustomer({ rootState, commit }) {
    const customer = await getCustomer({
      customerID: rootState.account.CustomerID
    })
    commit('setCustomer', customer)
  }
}

// mutations
const mutations = {
  setCustomer(state, customer) {
    state.customer = customer
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
