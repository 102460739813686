// initial state
const state = () => ({
  warehouses: [
    {
      value: 'Brugge',
      text: 'Brugge'
    },
    {
      value: 'Ieper',
      text: 'Ieper'
    },
    {
      value: 'Kortrijk',
      text: 'Kortrijk'
    }
  ]
})

// getters
const getters = {
  getWarehouses: state => state.warehouses
}

// actions
const actions = {}

// mutations
const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
