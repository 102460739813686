<template>
  <div id="app">
    <Header />
    <router-view />
    <back-to-top
      ><b-button variant="primary" class="ml-0 pr-3 pl-3 mr-0">
        <font-awesome-icon far icon="chevron-up" />
      </b-button>
    </back-to-top>
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/header/Index'
import Footer from '@/components/footer/Index'
import BackToTop from 'vue-backtotop'

export default {
  metaInfo: {
    title: `Uitleendienst`,
    titleTemplate: '%s | West-Vlaanderen',
    meta: [
      {
        name: `description`,
        content: `Officiële uitleendienst website van de Belgische provincie West-Vlaanderen.`,
        vmid: 'og:meta-description'
      }
    ]
  },

  components: {
    Header,
    Footer,
    'back-to-top': BackToTop
  }
}
</script>

<style lang="scss">
@import './assets/scss/cookiebar';
@import './assets/scss/calendar';

// .datepicker-item-disable {
//   pointer-events: none;
// }
.item-planner {
  .nav-tabs .nav-item:first-child {
    a {
      min-width: 247px;
    }
  }
}

a.nav-link.active {
  font-weight: 600;
}

input.form-control.datepicker-input {
  height: 43px;
  border-radius: 0.5rem;
}

.navbar.bg-light {
  background-color: #edf0f1 !important;
}
.navbar-brand {
  a {
    color: black !important;
  }
  img {
    height: 20px;
  }
}
.page-item.active .page-link {
  color: #fff !important;
}
a,
.btn.btn-link {
  color: #a73b35 !important;
}

a.btn,
.pagelink {
  color: white !important;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff !important;
}
.container {
  padding-left: 129.5px;
  padding-right: 129.5px;
}
header.toast-header {
  border: none;
  display: none;
}

.b-toast .toast {
  opacity: 1;
  background-color: #a73b35 !important;
  color: white;
  font-weight: 900;
}

.no-break {
  white-space: nowrap;
}
@import './assets/scss/bootstrap-variables';
@import 'node_modules/bootstrap/scss/bootstrap';
@import 'node_modules/bootstrap-vue/src/index.scss';
@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

@import url('https://fonts.googleapis.com/css?family=Nunito+Sans&display=swap');

@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&display=swap');

* {
  font-family: 'Nunito Sans', sans-serif;
}
html,
body {
  background-color: #f6f6f6 !important;
}
</style>
