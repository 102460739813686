const { api } = require('./Api')

async function getCustomer({ customerID }) {
  const result = await api.get(`/v2/shop/customers/${customerID}`)

  return result.data
}

async function patchCustomer({ customerID, customer }) {
  const result = await api.patch(`/v2/shop/customers/${customerID}`, {
    ...customer
  })

  return result.data
}

module.exports = { getCustomer, patchCustomer }
