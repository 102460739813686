<template>
  <header class="mb-3">
    <b-container>
      <b-row align-v="center">
        <b-col xs="12" md="5" class="text-center text-md-left">
          <router-link to="/">
            <img alt="Logo Provincie West-Vlaanderen" src="/img/logo.png" />
          </router-link>
        </b-col>
        <b-col col sm="12" md="7" class>
          <b-form inline class="header-buttons">
            <router-link v-if="user.UserName" to="/cart">
              <button class="btn btn-outline-primary mr-3 mb-2 mb-md-0">
                <font-awesome-icon icon="shopping-cart" />
                {{ cartCount > 0 ? cartCount : '' }}
              </button>
            </router-link>

            <router-link v-if="!user.UserName" to="/login">
              <b-button variant="primary" class="mr-3">Log in</b-button>
            </router-link>
            <router-link v-if="user.UserName" to="/account">
              <b-button variant="primary" class="mr-3 pl-3 pr-3 mb-2 mb-md-0">
                <font-awesome-icon icon="user-circle" />&nbsp;Mijn account
              </b-button>
            </router-link>
            <button
              v-if="user.UserName"
              class="mr-3 pl-3 pr-3 mb-2 mb-md-0 btn btn-secondary"
              @click="logout"
            >
              <font-awesome-icon icon="sign-out-alt" />&nbsp;Uitloggen
            </button>
          </b-form>
        </b-col>
      </b-row>
    </b-container>
    <secondary-navbar />
  </header>
</template>

<script>
import secondaryNavbar from './SecondaryNavbar'
export default {
  components: {
    'secondary-navbar': secondaryNavbar
  },
  data() {
    return {
      searchTerm: ''
    }
  },
  computed: {
    user: function() {
      return this.$store.getters.user
    },
    cartCount() {
      return this.$store.getters.cartCount
    }
  },
  methods: {
    logout: async function() {
      await this.$store.commit('unsetCart')
      await this.$store.commit('unsetUser', {
        reason: 'loggedOut=true'
      })

      //this.$router.push({ name: 'index' })
    },
    search: async function(event) {
      event.preventDefault()
      if (this.searchTerm.length > 1) {
        await this.$router.push({
          name: 'search',
          params: { searchTerm: this.searchTerm.toLowerCase() }
        })
        this.searchTerm = ''
      }
    }
  }
}
</script>

<style scoped lang="scss">
.header-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
  padding-top: 10px;
}
header {
  background-color: white;
}
.container {
  background-color: transparent;
}
.logo {
  height: 50px;
  margin-top: 10px;
}
.topbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.625rem 0;
  font-size: 0.875rem;
  > .container,
  > .container-fluid {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .topbar-text,
  .topbar-link {
    display: inline-block;
    margin-bottom: 0;
    text-decoration: none !important;
    > i {
      margin-right: 0.375rem;
      font-size: 1.15em;
      vertical-align: middle;
    }
  }

  // Carousel inside topbar
  .cz-carousel {
    [data-controls] {
      margin-top: -1rem;
      width: 2rem;
      height: 2rem;
      transition: color 0.2s ease-in-out;
      border: 0;
      background: 0;
      font-size: 0.9558823529rem;
      line-height: 2rem;
    }
    [data-controls='prev'] {
      left: -2.2rem;
    }
    [data-controls='next'] {
      right: -2.2rem;
    }
  }
}
@-moz-document url-prefix() {
  .topbar .cz-carousel [data-controls] {
    margin-top: -0.875rem;
  }
}
</style>
