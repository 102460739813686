import dayjs from 'dayjs'
// initial state
const state = () => ({
  warehouse: null,
  startDate: null,
  endDate: null,
  formattedStartDate: null,
  formattedEndDate: null
})

// getters
const getters = {
  startDate: state => state.startDate,
  endDate: state => state.endDate,
  formattedStartDate: state => state.formattedStartDate,
  formattedEndDate: state => state.formattedEndDate,
  warehouse: state => state.warehouse
}

// actions
const actions = {
  setStartDate({ commit }, date) {
    commit('setStartDate', date)
    commit('setFormattedStartDate', dayjs(date).format('DD/MM/YYYY'))
  },
  setEndDate({ commit }, date) {
    commit('setEndDate', date)
    commit('setFormattedEndDate', dayjs(date).format('DD/MM/YYYY'))
  },
  setWarehouse({ commit }, warehouse) {
    commit('setWarehouse', warehouse)
  }
}

// mutations
const mutations = {
  reset(state) {
    state.warehouse = null
    state.startDate = null
    state.endDate = null
    state.formattedStartDate = null
    state.formattedEndDate = null
  },
  setSelectedWarehouse(state, warehouse) {
    state.warehouse = warehouse
  },
  setStartDate(state, date) {
    state.startDate = date
  },
  setFormattedStartDate(state, date) {
    state.formattedStartDate = date
  },
  setEndDate(state, date) {
    state.endDate = date
  },
  setFormattedEndDate(state, date) {
    state.formattedEndDate = date
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
